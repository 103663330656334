// Copyright © 202w Move Closer

import { ComponentsStructureConfig } from '@/shared/core/src/support/mixins'
import { FiltersDrawerMode } from '@/shared/core/src/front/shared/molecules/FiltersDrawer/FiltersDrawer.config'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 *
 */
export const configuration: ComponentsStructureConfig = {
  structure: {
    AccountView: {
      processingOrderStatus: 'pendingPayment',
      ongoingShipmentStatus: 'complete'
    },
    AddedToCartModalComponent: {
      layout: 'rich',
      loadSuggestedProductsByDelivery: true,
      shouldSendDynamicContentEvents: true
    },
    AddedToCartDrawerComponent: {
      loadSuggestedProductsByDelivery: true,
      shouldSendDynamicContentEvents: true
    },
    //
    // Global strategy for auth.
    authActionStrategy: 'router',
    //
    //
    ArticlesTeaserComponent: {
      hasCTA: true,
      carousel: {
        hasPagination: false,
        responsive: {
          mobile: {
            perPage: 1,
            hasButtons: false,
            adaptiveHeight: true
          },
          desktop: { perPage: 3 }
        }
      }
    },
    ArticleCardComponent: {
      showDate: false,
      useSingleLink: true,
      buttonVariant: '',
      headingLevel: 2,
      maxLines: {
        default: 2,
        horizontal: 8
      }
    },
    ArticleHeaderComponent: {
      displayDate: false,
      displayShare: false
    },
    ArticlesListComponent: {
      filtersConfig: {
        component: {
          icons: {
            accordionIcon: 'CaretDown',
            closeIcon: 'Close'
          },
          layout: 'popover'
        },
        wrapper: {
          shouldUseMobileFilters: false
        }
      }
    },
    BuyerContextSwitchComponent: {
      acceptPrivacyForLoggedInUser: true
    },
    CartCouponsComponent: {
      errorIcon: 'Error',
      trashIcon: 'Trash'
    },
    CartItemComponent: {
      layout: 'basic',
      shouldLinkCategory: false
    },
    CartSummaryComponent: {
      changedOverall: true,
      couponsLocation: 'top',
      shouldDisplayTotalOnMobile: true,
      showCoupons: false,
      showBenefits: true,
      showBundleDiscounts: true,
      showShippingPreview: true,
      showFreeDeliveryProgress: false,
      showTitle: true,
      showTaxes: false
    },
    CartView: {
      layout: 'enhanced',
      cartIcons: [
        'ApplePay', 'Blik', 'GooglePay', 'MasterCard', 'Pay24Now', 'PayPo', 'Przelewy24', 'Visa'
      ],
      loadSuggestedProductsByDelivery: true,
      shouldLoadGifts: true,
      shouldLoadSamples: true,
      shouldLoadSuggestedProducts: true,
      shouldSendDynamicContentEvents: true,
      carouselConfig: {
        autoplay: false,
        centralize: false,
        delay: 2000,
        hasPagination: false,
        hasButtons: true,
        initialSlide: 0,
        loop: false,
        pauseOnHover: false,
        responsive: {
          mobile: { perPage: 2.3 },
          desktop: { perPage: 4 }
        },
        spaceBetween: 24
      }
    },
    ChangeAddressFormFields: {
      shouldChangePostalCodeLayout: true,
      shouldHaveCountryForm: false,
      shouldMaskPostalCode: true
    },
    CheckoutView: {
      componentName: 'CheckoutTwo',
      setIsSignupRequestedInCart: true
    },
    CheckoutOverviewComponent: {
      possibleAgreements: ['email-promotion', 'sms-promotion', 'reviews-request']
    },
    ChangeAddressForm: {
      hasSavedAddresses: false,
      isDefaultEditing: true,
      shouldBeFluid: true,
      validatorsMap: {
        address: 'required|string|min:4',
        acceptContent: 'accepted',
        billingAddress: 'required_if:sameBillingAddress,false|string|min:4',
        billingCity: 'required_if:sameBillingAddress,false|string|min:2',
        billingPostalCode: 'required_if:sameBillingAddress,false|string|min:5|max:7',
        city: 'required|string|min:2',
        company: 'filled|string|min:2',
        country: 'required|string|min:2',
        email: 'required|email',
        firstName: 'required|string|min:3',
        hasInvoice: 'boolean',
        invoiceCompany: 'required_if:hasInvoice,true|string|min:2',
        invoiceNip: 'required_if:hasInvoice,true|string|min:10|max:10',
        isSignupRequested: 'boolean',
        lastName: 'required|string|min:2',
        password: 'required_if:isSignupRequested,true|string|min:6|confirmed',
        passwordConfirmation: 'nullable',
        phone: 'required|string|min:9',
        postalCode: 'required|regex:[0-9]{2}-[0-9]{3}',
        sameBillingAddress: 'boolean'
      }
    },
    CircularStepperComponent: {
      steps: [
        {
          type: 'data',
          version: 'simplified',
          auth: false,
          registering: false,
          showWhenDataStepCompleted: false,
          excludeWhenLoyalty: false
        },
        {
          type: 'benefits',
          version: 'general',
          auth: true,
          registering: true,
          showWhenDataStepCompleted: false,
          excludeWhenLoyalty: false
        },
        {
          type: 'flightData',
          version: 'general',
          auth: false,
          registering: false,
          showWhenDataStepCompleted: true,
          excludeWhenLoyalty: true,
          skipWhenDefault: true,
          skipWhenSet: true
        },
        {
          type: 'shipping',
          version: 'simplified',
          auth: false,
          registering: false,
          showWhenDataStepCompleted: false,
          excludeWhenLoyalty: false
        },
        {
          type: 'payments',
          version: 'general',
          auth: false,
          registering: false,
          showWhenDataStepCompleted: false,
          excludeWhenLoyalty: false
        }
      ],
      considerOtherStepsWhenDataStepIsSet: true,
      considerOtherStepsWhenUserRegisters: true,
      considerOtherStepsWhenLoyalty: true
    },
    ConsentsRequestView: {
      consents: [
        {
          option: 'acceptContent',
          hasDescription: false,
          required: true
        },
        {
          option: 'emailOffers',
          hasDescription: true,
          required: false
        },
        {
          option: 'smsOffers',
          hasDescription: true,
          required: false
        }
      ]
    },
    ContactFormComponent: {
      consents: [
        {
          option: 'acceptPrivacy',
          hasDescription: false,
          required: true
        },
        {
          option: 'emailOffers',
          hasDescription: true,
          required: false
        },
        {
          option: 'smsOffers',
          hasDescription: true,
          required: false
        }
      ],
      subjects: [
        { value: 'Moje zamówienie', label: 'Moje zamówienie' },
        { value: 'Zwrot/reklamacja', label: 'Zwrot/reklamacja' },
        { value: 'Pytanie o produkt', label: 'Pytanie o produkt' },
        { value: 'Współpraca handlowa, marketingowa', label: 'Współpraca handlowa, marketingowa' },
        { value: 'Sprawy HR', label: 'Sprawy HR' },
        { value: 'Inne', label: 'Inne' }
      ],
      input: {
        animatedLabel: false
      }
    },
    CustomerDetailsStep: {
      withSocials: true,
      setIsSignupRequestedInCart: true,
      shouldFormatPostalCode: true,
      sendCustomerDetailsEvent: true,
      validatorsMap: {
        address: 'required|string|min:4',
        acceptContent: 'accepted',
        billingAddress: 'required_if:sameBillingAddress,false|string|min:4',
        billingCity: 'required_if:sameBillingAddress,false|string|min:2',
        billingPostalCode: 'required_if:sameBillingAddress,false|string|min:5|max:7',
        city: 'required|string|min:2',
        company: 'filled|string|min:2',
        country: 'required|string|min:2',
        email: 'required|email',
        firstName: 'required|string|min:3',
        hasInvoice: 'boolean',
        invoiceCompany: 'required_if:hasInvoice,true|string|min:2',
        invoiceNip: 'required_if:hasInvoice,true|string|min:10|max:10',
        isSignupRequested: 'boolean',
        lastName: 'required|string|min:2',
        password: 'required_if:isSignupRequested,true|string|min:6|confirmed',
        passwordConfirmation: 'nullable',
        phone: 'required|string|min:9',
        postalCode: 'required|regex:[0-9]{2}-[0-9]{3}',
        sameBillingAddress: 'boolean'
      },
      updateCartOnMount: true,
      excludedSocialsLogin: []
    },
    DeliveryDetailsStepComponent: {
      input: {
        hasAnimatingLabel: false
      },
      validatorsMap: {
        address: 'required|string|min:4',
        acceptContent: 'accepted',
        billingAddress: 'required_if:sameBillingAddress,false|string|min:4',
        billingCity: 'required_if:sameBillingAddress,false|string|min:2',
        billingPostalCode: 'required_if:sameBillingAddress,false|string|min:5|max:7',
        city: 'required|string|min:2',
        company: 'filled|string|min:2',
        country: 'required|string|min:2',
        email: 'required|email',
        firstName: 'required|string|min:3',
        hasInvoice: 'boolean',
        invoiceCompany: 'required_if:hasInvoice,true|string|min:2',
        invoiceNip: 'required_if:hasInvoice,true|string|min:10|max:10',
        isSignupRequested: 'boolean',
        lastName: 'required|string|min:2',
        password: 'required_if:isSignupRequested,true|string|min:6|confirmed',
        passwordConfirmation: 'nullable',
        phone: 'required|string|min:9',
        postalCode: 'required|regex:[0-9]{2}-[0-9]{3}',
        sameBillingAddress: 'boolean'
      }
    },
    FilterBadges: {
      clearWhenEmpty: true,
      closeIcon: 'Close',
      hasClearAllIcon: true
    },
    Filters: {
      icons: {
        accordionIcon: 'ArrowDown',
        closeIcon: 'Close'
      },
      layout: 'accordion',
      shouldApplyLoader: true,
      shouldSelectedFiltersBeOpen: true
    },
    FiltersDrawer: {
      hasCategoryTitle: true,
      mode: FiltersDrawerMode.InstantApply,
      shouldRenderEmptyState: false
    },
    FilterListComponent: {
      disableUnavailableFilters: true,
      sortUnavailable: true
    },
    FiltersWrapperComponent: {
      filterIcon: 'FiltersTrigger',
      shouldAnimate: true,
      shouldImplementMobileHeading: true
    },
    FooterComponent: {
      shouldLoadTrusted: true
    },
    FooterModule: {
      hasAdditionalContent: true
    },
    GalleryComponent: {
      closeIcon: 'Close'
    },
    HeroModule: {
      carouselConfig: {
        autoHeight: true,
        autoplay: false,
        centralize: false,
        delay: 5000,
        hasPagination: true,
        hasButtons: false,
        initialSlide: 0,
        inLoop: true,
        pauseOnHover: true,
        perPage: 1,
        responsive: {
          mobile: {
            hasButtons: false,
            autoplay: false,
            perPage: 1
          },
          desktop: {
            hasButtons: true,
            autoplay: true,
            perPage: 1
          }
        }
      },
      hasBenefitsBar: false
    },
    InPostModalComponent: {
      isSearchByPostcode: false,
      showPaymentOptions: false
    },
    LoginFormComponent: {
      hasLeadText: true,
      form: {
        hasLabel: true,
        reverseOrder: true
      },
      input: {
        animatedLabel: false
      },
      password: {
        simple: true
      },
      reverseOrder: true,
      socials: {
        display: true, // todo: sso
        simple: false,
        align: 'column',
        exclude: []
      },
      isAlertUnderForm: true
    },
    MegaMenuComponent: {
      hasAdditionalContent: true,
      hasPagination: true,
      hasAdditionalContainer: true,
      navCategoryItemAsHeading: false
    },
    NavbarModule: {
      type: 'withOpenSearch',
      useDrawer: false,
      shouldHideMobileNav: true,
      showLanguageSwitcher: false,
      showDynamicResults: true,
      shouldSwitchLogoWhenPremium: true,
      icons: {
        caret: 'CaretDown',
        menu: 'Hamburger',
        mainLinks: 'CaretDown',
        submenu: 'CaretRight',
        wishlist: 'Heart',
        cart: 'Tote',
        cartFull: 'ShoppingCartFull'
      },
      isNameVisible: true,
      loginStrategy: 'router',
      cartStrategy: 'router',
      menuOpeningStrategyOnTablet: 'icon',
      staticLinkIcons: [
        {
          id: 'account',
          order: 1,
          icon: 'User'
        },
        {
          order: 2,
          id: 'favouriteProducts',
          icon: 'Heart'
        }
      ],
      showCartIconWithoutPrice: true,
      mobileNavIcons: [
        {
          id: 'logo',
          label: 'logo',
          order: 1,
          icon: {
            default: 'SimpleStoreLogo',
            active: 'SimpleStoreLogo'
          }
        },
        {
          id: 'menuWithSearch',
          label: 'menuWithSearch',
          order: 2,
          icon: {
            default: 'SearchWithLines',
            active: 'Close'
          }
        },
        {
          id: 'cart',
          label: 'cart',
          order: 3,
          icon: {
            default: 'Tote',
            active: 'ShoppingCartFull'
          }
        },
        {
          id: 'account',
          label: 'account',
          order: 4,
          icon: {
            default: 'User',
            active: 'User'
          }
        },
        {
          id: 'favouriteProducts',
          label: 'favouriteProducts',
          order: 5,
          icon: {
            default: 'Heart',
            active: 'Heart'
          }
        }
      ],
      possibleStaticLinks: {
        MyAccount: {
          link: 'account'
        }
      }
    },
    NewsletterModule: {
      alertButtonTheme: 'secondary',
      buttonHasLabel: false,
      buttonIcon: 'Envelope',
      buttonTheme: 'primary',
      layout: 'rich'
    },
    OrdersListItemComponent: {
      daysForPaymentRetry: 7,
      layout: 'basic',
      shouldSeparateBundleDiscounts: true,
      showGiftCardPayment: true,
      paymentIcons: {
        banktransfer: '/assets/images/payments/przelew.png',
        przelewy24: '/assets/images/payments/przelewy24.svg',
        przelewy24_blik: '/assets/images/payments/blik.svg',
        przelewy24_card: '/assets/images/payments/credit-card.svg',
        przelewy24_apple_pay: '/assets/images/payments/apple_pay.svg',
        przelewy24_google_pay: '/assets/images/payments/google_pay.svg',
        przelewy24_pay_po: '/assets/images/payPo.svg'
      }
    },
    OrdersView: {
      hasMargin: false
    },
    PaymentStep: {
      // TODO: FYI - 154, 178 => inner methods of Przelewy24
      // @seeAlso: 'shippingsPaymentsMap'
      drivers: ['przelewy24_blik', 'przelewy24', '147', '227', '239', '238', '178', 'cashondelivery', 'giftcard', 'free'],
      icons: {
        banktransfer: '/assets/images/payments/przelew.png',
        przelewy24: '/assets/images/payments/przelewy24.svg',
        przelewy24_blik: '/assets/images/payments/blik.svg',
        przelewy24_card: '/assets/images/payments/credit-card.svg',
        przelewy24_apple_pay: '/assets/images/payments/apple_pay.svg',
        przelewy24_google_pay: '/assets/images/payments/google_pay.svg',
        przelewy24_pay_po: '/assets/images/payPo.svg'
      },
      // Map capable of reducing payment methods list based on currently selected shipping method
      // e.g. 'DHL Cash on delivery' (methodCode: 'dhlpopcod') shipping method should not render online payment methods
      shippingsPaymentsMap: {
        dhlpop: ['przelewy24_blik', 'przelewy24', '147', '227', '239', '238', '178', 'giftcard', 'free'],
        dhlpopcod: ['przelewy24_blik', 'przelewy24', '147', '227', '239', '238', '178']
      },
      /**
       * Ordering of payments methods in checkout.
       * WARNING: IDs may vary on different environments.
       */
      // paymentsOrder:{
      //   // TB / Local,
      //   154: 10,
      //   przelewy24: 20,
      //   giftcard: 30,
      //   178: 40,
      //   cashondelivery: 50
      // },
      paymentsOrder: {
        // PROD
        przelewy24_blik: 1, // blik
        147: 3,
        przelewy24: 2,
        227: 4, // paypo
        238: 5, // google
        239: 6, // apple
        178: 7, // cod
        giftcard: 8,
        cashondelivery: 9
      },
      shouldReducePaymentMethods: true,
      shouldDefaultAcceptConsent: true,
      useGiftCardsForPayment: true
    },
    PrivacyModal: {
      defaultChecked: false,
      hasPrimaryBtn: true
    },
    ProductCard: {
      additionalAttributes: ['hasGift', 'isSponsored'],
      disabledBadgeIcons: ['isNew'],
      mainAttributes: [
        'isNatural',
        'isNew',
        'isBestseller',
        'isSale',
        'isFaF',
        'isInPresale',
        'hasFreeDelivery',
        'bundledProducts'
      ],
      modalSize: 'large',
      productCardType: 'rich',
      useDrawer: true,
      shouldAddDynamicContentSyneriseParam: true,
      useStarsRateAsForm: false
    },
    ProductHeaderComponent: {
      badgesOnGallery: true,
      defaultMaxRating: 5,
      favouriteAsIcon: true,
      hasBenefitsBar: false,
      hasDeliveryTimer: true,
      hasGiftsBox: true,
      hasNotificationForm: true,
      hasOrderDetails: true,
      icons: {
        addToCartButtonIcon: 'Tote',
        heartEmptyIcon: 'HeartEmpty',
        heartFullIcon: 'HeartFull',
        infoIcon: 'Info'
      },
      modalSize: 'large',
      notifyButtonIcon: 'Envelope',
      promotionBadgeHasLabel: true,
      shouldHaveSeparatedRating: true,
      useDrawer: true,
      useVendorReviews: true,
      variantSwitcherShowChosen: true,
      shouldHaveVisibleRating: true,
      showSingleVariantRating: true
    },
    ProductsListComponent: {
      hasCategoryNavigation: true,
      listDisplayControlsInDrawer: false,
      paginationHasDirectionButtons: false,
      paginationHasGroupedLayout: true,
      shouldImplementMobileHeading: false
    },
    ProductsTeaserComponent: {
      carouselConfig: {
        autoplay: false,
        responsive: {
          mobile: {
            perPage: 2.3,
            variableWidth: true,
            hasButtons: false
          },
          desktop: {
            perPage: 5,
            centerMode: true,
            centerPadding: '0',
            variableWidth: false
          }
        }
      },
      loadSuggestedProductsByDelivery: true,
      shouldSendDynamicContentEvents: true
    },
    ProductReviewsModule: {
      useVendorReviews: true
    },
    ProfileWrapperComponent: {
      hasContainerClass: true,
      hasWelcomeHeading: false,
      shouldCenterNavigationElements: true
    },
    ProfileView: {
      layout: 'simple',
      myDataContentTypes: ['email', 'firstName', 'lastName']
    },
    RateSummaryComponent: {
      omnibusDetailsUrl: 'https://help.etrusted.com/hc/pl/articles/4419944605341'
    },
    RequestResetLinkFormComponent: {
      backIcon: 'ArrowLeft',
      isAlertUnderForm: true
    },
    ResetPasswordFormComponent: {
      onSuccessStrategy: 'banner',
      subtitle: true,
      password: {
        simple: false
      },
      input: {
        animatedLabel: false
      }
    },
    ReviewsTeaser: {
      carouselConfig: {
        autoplay: false,
        hasPagination: false,
        responsive: {
          mobile: {
            perPage: 1.2,
            variableWidth: true,
            hasButtons: false
          },
          desktop: {
            perPage: 3.5,
            variableWidth: false
          }
        }
      },
      reviewsPeriod: '365days'
    },
    SearchResultsView: {
      hasControls: true,
      resultsPerPage: 80,
      usePerPageParam: false,
      useRouteParams: true
    },
    SearchResultsComponent: {
      calculateOffsetForNavbar: true,
      searchInputClosable: false,
      searchInputOpenOnStart: true,
      searchInputHasButton: true,
      hasCloseIcon: true,
      hasSearchHints: true,
      shouldRelyOnIsOpen: true,
      shouldSetResultsPosition: true,
      sliceResults: 3,
      shouldWatchResize: true
    },
    SearchResultProductItemComponent: {
      useEnhancedTranslator: true
    },
    SignupFormComponent: {
      variant: 'simplified',
      consents: {
        group: true,
        options: [
          {
            option: 'acceptContent',
            hasDescription: false,
            required: true
          },
          {
            option: 'emailOffers',
            hasDescription: true,
            required: false
          },
          {
            option: 'smsOffers',
            hasDescription: true,
            required: false
          }
        ]
      },
      isAlertUnderForm: true,
      socials: {
        display: true, // todo: sso,
        exclude: []
      }
    },
    Shippings: {
      template: {
        withPlus: false
      }
    },
    SimpleForm: {
      layout: 'basic'
    },
    QuantityPickerComponent: {
      minusIcon: 'Minus',
      plusIcon: 'Plus'
    },
    ThankYouComponent: {
      createAccountStrategy: 'router'
    },
    InitTrustedShopMixin: {
      reviewsPeriod: '365days',
      shouldLoadTrusted: true
    }
  },
  options: {},
  modules: {
    navLinks: {
      loyaltyTabs: [
        {
          label: 'benefitPrograms',
          priority: 50
        }
      ],
      profileTabs: [
        {
          label: 'me',
          priority: 100
        },
        {
          label: 'addressesBillings',
          priority: 80
        },
        {
          label: 'myData',
          priority: 70
        },
        {
          label: 'newsletter',
          priority: 60
        }
      ],
      orderTabs: [
        {
          label: 'historyOfOrders',
          priority: 90
        }
      ],
      productTabs: []
    },
    possibleOrderStatuses: [
      'oexWKompletacji',
      'processing',
      'oexWydane',
      'fraud',
      'pendingPayment',
      'paymentReview',
      'pending',
      'holded',
      'complete',
      'oexNiedoreczone',
      'closed',
      'canceled',
      'pendingPaypal',
      'paypalReversed',
      'paypalCanceledReversal'
    ],
    highlightedOrderStatuses: [
      'canceled', 'holded', 'fraud'
    ],
    retryOrderStatuses: [
      'paymentReview'
    ],
    returnOrderStatuses: [
      'complete'
    ]
  }
}
