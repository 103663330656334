
















import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import { ContainerProps } from './Container.contracts'
import { containerProps, useContainer } from './Container.hooks'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const Container = defineComponent({
  name: 'Container',
  props: containerProps,
  setup (props: ContainerProps) {
    return useContainer(props, getCurrentInstance())
  }
})
export default Container
