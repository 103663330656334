// Copyright © 2022 Move Closer

import { Injectable, IObserver, IWindow } from '@movecloser/front-core'

import {
  AnalyticsConfig,
  ContactFormConfig, CustomerDetailsConfig,
  DynamicContentConfig,
  PageLoadedEvent
} from '../contracts'
import { UserModel } from '../../auth/shared'
import {
  AbstractEvent,
  AddPaymentInfoConfig,
  AddPaymentInfoEvent,
  AddShippingInfoConfig,
  AddShippingInfoEvent,
  AddToCartConfig,
  AddToCartEvent,
  BeginCheckoutConfig,
  BeginCheckoutEvent,
  LoginEvent,
  PageViewEvent,
  PurchaseConfig,
  PurchaseEvent,
  RemoveFromCartConfig,
  RemoveFromCartEvent,
  SubscribeConfig,
  SubscribeEvent,
  Trackify,
  TRACKIFY_DEBUG,
  TRACKIFY_GTM,
  TRACKIFY_SYNERISE,
  TRACKIFY_USERCOM,
  TRACKIFY_TRADEDOUBLER,
  TRACKIFY_CENEO,
  UnsubscribeEvent,
  UserDataEvent,
  ViewCartConfig,
  ViewCartEvent,
  ViewItemConfig,
  ViewItemEvent, ViewItemListConfig, ViewItemListEvent
} from '@kluseg/trackify/dist/main'
import { PrePurchaseEvent } from '@kluseg/trackify/dist/events/sales/pre-purchase.event'
import { TypPurchaseEvent } from '@kluseg/trackify/dist/events/sales/typ-purchase.event'
import { ISiteService } from '../../../contexts'
import { ContactEvent } from '../events/contact.event'
import { DynamicContent, DynamicContentAction } from '../events/dynamicContent.event'
import { PrivacyOptions } from '../../shared'
import { SignUpEvent } from '../events/sign-up.event'
import { IInputMaskService } from '../../shared/services/inputmask'
import { InputField } from '../../shared/services/inputmask/config'

declare global {
  interface Window {
    gtag: (...args: any[]) => void
  }
}

/**
 * Class to observe common UI changes and send analytics to drivers
 *
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 * @author Piotr Niewczas <piotr.niewczas@movecloser.pl> <update>
 */
@Injectable()
export class GAObserver implements IObserver {
  protected consents: Record<PrivacyOptions, boolean> | null = null
  protected isInit: boolean = false
  protected trackify: Trackify | undefined

  private staticCurrency: 'PLN' | 'USD' = 'PLN'

  constructor (
    protected analyticsConfig: AnalyticsConfig,
    protected siteService: ISiteService,
    protected windowService: IWindow,
    protected inputMaskService?: IInputMaskService
  ) {
    try {
      this.trackify = new Trackify()
      if (this.analyticsConfig.TRACKIFY_DEBUG) {
        this.trackify.useDriver(TRACKIFY_DEBUG)
      }
      if (this.analyticsConfig.TRACKIFY_GTM) {
        this.trackify.useDriver(TRACKIFY_GTM, { layerId: 'dataLayer' })
      }
      if (this.analyticsConfig.TRACKIFY_SYNERISE) {
        this.trackify.useDriver(TRACKIFY_SYNERISE)
      }
      if (this.analyticsConfig.TRACKIFY_USERCOM) {
        this.trackify.useDriver(TRACKIFY_USERCOM)
      }
      if (this.analyticsConfig.TRACKIFY_TRADEDOUBLER) {
        this.trackify.useDriver(TRACKIFY_TRADEDOUBLER)
      }
      if (this.analyticsConfig.TRACKIFY_CENEO) {
        this.trackify.useDriver(TRACKIFY_CENEO)
      }
      this.trackify.loadDrivers().then(() => {
        this.isInit = true
      })
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.log(error.message)
    }
  }

  /**
   * List of observables.
   */
  public get observableEvents () {
    return {
      'app:authorization.login': 'onLogin',
      'app:authorization.sign_up': 'onSignUp',
      'app:cart.add': 'onAddToCart',
      'app:cart.remove': 'onRemoveFromCart',
      'app:cart.view': 'onViewCart',
      'app:consents.update': 'onUpdateConsent',
      'app:contactForm.submit': 'onContactForm',
      'app:checkout.begin': 'onBeginCheckout',
      'app:checkout.addPaymentInfo': 'onAddPaymentInfo',
      'app:checkout.addShippingInfo': 'onAddShippingInfo',
      'app:checkout.purchase': 'onPurchase',
      'app:checkout.prePurchase': 'onPrePurchase',
      'app:checkout.purchaseTyp': 'onTypPurchase',
      'app:customer_details': 'onCustomerDetails',
      'app:dynamicContent': 'onDynamicContent',
      'app:page.loaded': 'onPageView',
      'app:product.view': 'onViewItem',
      'app:product_list.view': 'onViewItemList',
      'app:newsletter.subscribe': 'onSubscribeNewsletter',
      'app:newsletter.unsubscribe': 'onUnsubscribeNewsletter',
      'app:smsOffers.subscribe': 'onSubscribeToSmsOffers',
      'app:user.change_data': 'onUserDataChange'
    }
  }

  /**
   * Function to track page changes
   * @private
   */
  public onPageView (event: PageLoadedEvent): void {
    this.observe(new PageViewEvent({
      language: event.locale,
      pagePath: event.path,
      pageTitle: event.title,
      pageType: event.pageType,
      currency: this.staticCurrency,
      turnOffPageViewForSPA: event.turnOffPageViewForSPA,
      customEventName: 'pageview'
    }))
  }

  public onAddToCart (event: AddToCartConfig): void {
    this.observe(new AddToCartEvent({ ...event, currency: this.staticCurrency }))
  }

  public onAddPaymentInfo (event: AddPaymentInfoConfig): void {
    this.observe(new AddPaymentInfoEvent({
      ...event, currency: this.staticCurrency
    }))
  }

  public onAddShippingInfo (event: AddShippingInfoConfig): void {
    this.observe(new AddShippingInfoEvent({
      ...event,
      currency: this.staticCurrency
    }))
  }

  public onBeginCheckout (event: BeginCheckoutConfig): void {
    this.observe(new BeginCheckoutEvent({
      ...event,
      currency: this.staticCurrency
    }))
  }

  public onCustomerDetails (event: CustomerDetailsConfig): void {
    const storeCode = this.siteService.getActiveSite().getProperty('storeCode', '')
    const locale = this.siteService.getActiveSiteLocale()

    const config = {
      email: event.email,
      firstname: event.firstname,
      lastname: event.lastname,
      company: event.company,
      street: event.street,
      postalcode: event.postalCode,
      city: event.city,
      countrycode: event.countryCode,
      phone: this.formatPhone(event.phone),
      vatid: event.vatId,
      language: locale as string || '',
      storecode: storeCode as string || '',

      emailOffers: event.emailOffers ? '1' : '0',
      smsOffers: event.smsOffers ? 'true' : 'false'
    }

    // Do not send falsy value for these fields.
    if (typeof event.emailOffers === 'undefined') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete config.emailOffers
    }
    if (typeof event.smsOffers === 'undefined') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete config.smsOffers
    }
    this.observe(new ContactEvent(config))
  }

  public onLogin (event: UserModel): void {
    this.observe(new LoginEvent({
      method: 'web',
      firstname: event.firstName,
      lastname: event.lastName,
      email: event.email,
      id: event.email
    }))
  }

  public onSignUp (event: UserModel): void {
    this.observe(new SignUpEvent({
      method: 'web',
      firstname: event.firstName,
      lastname: event.lastName,
      email: event.email,
      id: event.email,
      // allow_marketing: event.emailOffers ? 'true' : 'false',
      // allow_sms_marketing: event.smsOffers ? 'true' : 'false'
      emailOffers: event.emailOffers ? '1' : '0',
      smsOffers: event.smsOffers ? 'true' : 'false'
    }))
  }

  public onPrePurchase (event: PurchaseConfig): void {
    this.observe(new PrePurchaseEvent({ ...event, currency: this.staticCurrency }))
  }

  public onPurchase (event: PurchaseConfig): void {
    this.observe(new PurchaseEvent({
      ...event,
      currency: this.staticCurrency,
      event: `${process.env.VUE_APP_TRADEDOUBLER_EVENT_ID}`,
      organization: `${process.env.VUE_APP_TRADEDOUBLER_ORGANIZATION_ID}`
    }))
  }

  public onTypPurchase (event: PurchaseConfig): void {
    this.observe(new TypPurchaseEvent({ ...event, currency: this.staticCurrency }))
  }

  public onRemoveFromCart (event: RemoveFromCartConfig & { deletedItems: RemoveFromCartConfig['items'] }): void {
    this.observe(new RemoveFromCartEvent({
      ...event,
      items: event.deletedItems,
      currency: this.staticCurrency
    }))

    this.observe(new ViewCartEvent({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      totalQuantity: event.totalQuantity ?? 0,
      value: event.value,
      items: event.items,
      currency: this.staticCurrency
    }))
  }

  public onUserDataChange (event: UserModel) {
    this.observe(new UserDataEvent({
      id: event.id as string,
      email: event.email,
      firstname: event.firstName,
      lastname: event.lastName,
      dateOfBirth: event.dateOfBirth,
      phone: this.formatPhone(event.phone)
      // role: event.role,
    }))
  }

  public onViewCart (event: ViewCartConfig): void {
    this.observe(new ViewCartEvent({ ...event, currency: this.staticCurrency }))
  }

  public onViewItem (event: ViewItemConfig): void {
    // const product = event.items[0]
    // Hack to delay event. It is done to emit event 'page_view' first.
    setTimeout(() => {
      this.observe(new ViewItemEvent({ ...event, currency: this.staticCurrency }))
    }, 0)
  }

  public onSubscribeNewsletter (event: SubscribeConfig): void {
    const storeCode = this.siteService.getActiveSite().getProperty('storeCode', '')
    const locale = this.siteService.getActiveSiteLocale()

    this.observe(new SubscribeEvent({
      email: event.email,
      list: event.list,
      language: locale as string || '',
      allow_marketing: event.allow_marketing,
      allow_sms_marketing: event.allow_sms_marketing,
      allow_policy: event.allow_policy,
      storeCode: storeCode as string || ''
    }))
  }

  // FIXME (when trackify event is ready)
  public onSubscribeToSmsOffers (event: any): void { // TODO: Use proper event config interface
    // this.observe()
  }

  private static capitalizeFirstLetter (string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  public onContactForm (event: ContactFormConfig): void {
    const storeCode = this.siteService.getActiveSite().getProperty('storeCode', '')
    const locale = this.siteService.getActiveSiteLocale()

    const emailUsername = event.email.split('@')[0]
    const fallbackFirstName = GAObserver.capitalizeFirstLetter(emailUsername.split('.')[0] ?? '')
    const fallbackLastName = GAObserver.capitalizeFirstLetter(emailUsername.split('.')[1] ?? '')

    const config = {
      email: event.email,
      name: event.name ?? `${fallbackFirstName} ${fallbackLastName}`,
      firstname: event.firstname ?? fallbackFirstName,
      lastname: event.lastname ?? fallbackLastName,
      subject: event.subject,
      message: event.message,
      emailOffers: event.emailOffers ? 'enabled' : 'disabled',
      smsOffers: event.smsOffers ? 'true' : 'false',
      acceptPrivacy: event.acceptPrivacy ? 'true' : 'false',
      language: locale as string || '',
      storeCode: storeCode as string || ''
    }

    this.observe(new ContactEvent(config))
  }

  public onDynamicContent (event: DynamicContentConfig): void {
    let action: string = ''
    let eventName: string = ''

    switch (event.action) {
      case DynamicContentAction.Click:
        action = DynamicContentAction.Click
        eventName = DynamicContentAction.Click
        break
      case DynamicContentAction.Show:
        action = DynamicContentAction.Show
        eventName = DynamicContentAction.Show
    }

    this.observe(new DynamicContent({
      action: event.action,
      eventUUID: event.eventUUID,
      unique: event.unique,
      createDate: event.createDate,
      label: event.label,
      title: event.title,
      url: event.url,
      recoId: event.recoId,
      source: event.source
    }, eventName, action))
  }

  public onUnsubscribeNewsletter (event: SubscribeConfig): void {
    this.observe(new UnsubscribeEvent({
      email: event.email
    }))
  }

  public onViewItemList (event: ViewItemListConfig): void {
    this.observe(new ViewItemListEvent(event))
  }

  public onUpdateConsent (consents: Record<PrivacyOptions, boolean>): void {
    this.updateGAConsents(consents)

    this.consents = consents
  }

  protected formatPhone (value: string | undefined): string | undefined {
    if (typeof value === 'undefined') {
      return
    }

    return this.inputMaskService?.formatInputValue('PL', InputField.PhoneDB, value)
  }

  protected observe (event: AbstractEvent): void {
    if (!this.isInit || !this.trackify) return

    this.trackify.track(event)
  }

  protected updateGAConsents (consents: Record<PrivacyOptions, boolean>): void {
    if (!this.windowService.native) {
      return
    }

    if (typeof this.windowService.native.gtag === 'undefined') {
      setTimeout(() => {
        this.updateGAConsents(consents)
      }, 1000)
    } else {
      this.windowService.native.gtag('consent', 'update', {
        ad_storage: consents.marketing ? 'granted' : 'denied',
        ad_user_data: consents.marketing ? 'granted' : 'denied',
        ad_personalization: consents.marketing ? 'granted' : 'denied',
        analytics_storage: consents.analytics ? 'granted' : 'denied',
        functionality_storage: consents.functional ? 'granted' : 'denied',
        personalization_storage: consents.functional ? 'granted' : 'denied',
        security_storage: consents.functional ? 'granted' : 'denied'
      })
    }
  }
}

export const GAEventObserverType = Symbol.for('GAEventObserver')
