import { AnyObject } from '@movecloser/front-core'
import { SyneriseUid } from '../../../modules/ProductsTeaser/ProductsTeaser.contracts'

export const PRODUCT_RECOMMENDATIONS_KEY = 'productRecommendations'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum SuggestedProductsSource {
  CartView = 'cart',
  AddToCartModal = 'modals',
  Synerise = 'synerise'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface ProductsRecommendationsData {
  automation: AutomationType
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface AutomationType {
  cart: RecommendationSourcePayload
  modals: RecommendationSourcePayload
  synerise?: RecommendationSourcePayload
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface RecommendationSourcePayload {
  type: string
  value?: SyneriseUid | null
}

export const SYNERISE_UUID_COOKIE_NAME = '_snrs_uuid'
